/**
  *@页面的tabber页面
  *首页 分类 购物车 我的
  *2022-08-02 kadiam
*/
<template>
  <div class="tabber flex-r-sa-c">
    <div class="tabber_btn flex-c-c-c" @click="changePath(1)">
      <van-image width="0.59rem" height="0.59rem" :src="myindex === 1?'https://hanxingkeji.oss-cn-shanghai.aliyuncs.com/shop2.0/public/tabber_full_01.png'
        :'https://hanxingkeji.oss-cn-shanghai.aliyuncs.com/shop2.0/public/tabber_01.png'"
      />
      <span :class="myindex === 1?'tabber_choosed tabber_message':'tabber_message'">首页</span>
    </div>
    <div class="tabber_btn flex-c-c-c" @click="changePath(5)">
      <van-image width="0.59rem" height="0.59rem" :src="myindex === 5?'https://hanxingkeji.oss-cn-shanghai.aliyuncs.com/shop2.0/public/tabber_full_05_01.png'
        :'https://hanxingkeji.oss-cn-shanghai.aliyuncs.com/shop2.0/public/tabber_05_01.png'"
      />
      <span :class="myindex === 5?'tabber_choosed tabber_message':'tabber_message'">云课堂</span>
    </div>
    <div class="tabber_btn flex-c-c-c" @click="changePath(2)">
      <van-image width="0.59rem" height="0.59rem" :src="myindex === 2?'https://hanxingkeji.oss-cn-shanghai.aliyuncs.com/shop2.0/public/tabber_full_02.png'
        :'https://hanxingkeji.oss-cn-shanghai.aliyuncs.com/shop2.0/public/tabber_02.png'"
      />
      <span :class="myindex === 2?'tabber_choosed tabber_message':'tabber_message'">分类</span>
    </div>
    <van-badge :content="$store.getters.totalShopping" color="#EA5C36">
      <div class="tabber_btn flex-c-c-c" @click="changePath(3)">
        <van-image width="0.59rem" height="0.59rem" :src="myindex === 3?'https://hanxingkeji.oss-cn-shanghai.aliyuncs.com/shop2.0/public/tabber_full_03.png'
          :'https://hanxingkeji.oss-cn-shanghai.aliyuncs.com/shop2.0/public/tabber_03.png'"
        />
        <span :class="myindex === 3?'tabber_choosed tabber_message':'tabber_message'">购物车</span>
      </div>
    </van-badge>

    <div class="tabber_btn flex-c-c-c" @click="changePath(4)">
      <van-image width="0.59rem" height="0.59rem" :src="myindex === 4?'https://hanxingkeji.oss-cn-shanghai.aliyuncs.com/shop2.0/public/tabber_full_04.png'
        :'https://hanxingkeji.oss-cn-shanghai.aliyuncs.com/shop2.0/public/tabber_04.png'"
      />
      <span :class="myindex === 4?'tabber_choosed tabber_message':'tabber_message'">我的</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Tabber',
  props: {
    index: { type: Number, default: null }
  },
  data() {
    return {
      myindex: this.index,
      alIndex: this.index
    }
  },
  methods: {
    changePath(path) {
      this.myindex = path
      if (this.alIndex !== path) {
        sessionStorage.setItem('tabberJump', true)
        if (path === 1) { this.$router.push('/home/index') }
        if (path === 2) { this.$router.push('/product/classify') }
        if (path === 3) { this.$router.push('/cart/cart') }
        if (path === 4) { this.$router.push('/user/my') }
        if (path === 5) { this.$router.push('/studyClass/studyIndex') }
      }
    }
  }
}
</script>
<style lang="scss">
.tabber {
  position: fixed;
  bottom: 0;
  width: 100%;
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
  background: #ffffff;
  border-top: 1px solid rgb(215, 215, 215);
  .tabber_message {
    font-size: 0.32rem;
    color: #b5b5b5;
  }
  .tabber_choosed {
    color: #0767ab;
  }
}
</style>
